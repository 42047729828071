import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { loadLayoutMiddleware } from "@/router/middleware/loadLayout.middleware";
import authMiddleware from "@/router/middleware/auth.middleware";
import guestMiddleware from "@/router/middleware/guest.middleware";
import checkUserRole from "@/router/middleware/checkUserRole.middleware";
import { RouteNamesEnum } from "@/router/router.types";
import { AppLayoutsEnum } from "@/layouts/layouts.types";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: RouteNamesEnum.dashboard,
        component: () => import('@/views/DashboardView.vue'),
        meta: {
            layout: AppLayoutsEnum.default
        },
        beforeEnter: [authMiddleware]
    },
    {
        path: '/contact',
        name: RouteNamesEnum.contact,
        component: () => import('@/views/ContactView.vue'),
        meta: {
            layout: AppLayoutsEnum.default
        },
        beforeEnter: [authMiddleware]
    },
    {
        path: '/individual-chat',
        name: 'individual_chat',
        component: () => import('@/views/IndividualChatView.vue'),
        meta: {
            layout: AppLayoutsEnum.default
        },
        beforeEnter: [authMiddleware]
    },
    {
        path: '/expert-chat',
        name: 'expert_chat',
        component: () => import('@/views/ExpertChatView.vue'),
        meta: {
            layout: AppLayoutsEnum.default
        },
        beforeEnter: [authMiddleware, checkUserRole]
    },
    {
        path: '/general',
        name: RouteNamesEnum.general,
        component: () => import('@/views/KnowledgeView.vue'),
        meta: {
            layout: AppLayoutsEnum.default
        },
        beforeEnter: [authMiddleware]
    },
    {
        path: '/management-fees',
        name: RouteNamesEnum.management_fees,
        component: () => import('@/views/ManagementFeesVue.vue'),
        meta: {
            layout: AppLayoutsEnum.default
        },
        beforeEnter: [authMiddleware]
    },
    {
        path: '/companies',
        name: RouteNamesEnum.companies,
        component: () => import('@/views/CompaniesView.vue'),
        meta: {
            layout: AppLayoutsEnum.default
        },
        beforeEnter: [authMiddleware]
    },
    {
        path: '/links',
        name: RouteNamesEnum.links,
        component: () => import('@/views/LinksView.vue'),
        meta: {
            layout: AppLayoutsEnum.default
        },
        beforeEnter: [authMiddleware]
    },
    {
        path: '/dashboard_text_slides',
        name: RouteNamesEnum.dashboard_text_slider,
        component: () => import('@/views/DashboardTextSlidesView.vue'),
        meta: {
            layout: AppLayoutsEnum.default
        },
        beforeEnter: [authMiddleware, checkUserRole]
    },
    {
        path: '/dashboard_greetings_slides',
        name: RouteNamesEnum.dashboard_greeting_slider,
        component: () => import('@/views/DashboardGreetingSlidesView.vue'),
        meta: {
            layout: AppLayoutsEnum.default
        },
        beforeEnter: [authMiddleware, checkUserRole]
    },
    {
        path: '/dashboard_special_offer_slider',
        name: RouteNamesEnum.dashboard_special_offer_slider,
        component: () => import('@/views/DashboardSpecialOffersSlidesView.vue'),
        meta: {
            layout: AppLayoutsEnum.default
        },
        beforeEnter: [authMiddleware, checkUserRole]
    },
    {
        path: '/users',
        name: RouteNamesEnum.users,
        component: () => import('@/views/UsersView.vue'),
        meta: {
            layout: AppLayoutsEnum.default
        },
        beforeEnter: [authMiddleware, checkUserRole]
    },
    {
        path: '/search',
        name: RouteNamesEnum.search,
        component: () => import('@/views/SearchView.vue'),
        meta: {
            layout: AppLayoutsEnum.default
        },
        beforeEnter: [authMiddleware]
    },
    {
        path: '/login',
        name: RouteNamesEnum.login,
        component: () => import('@/views/LoginView.vue'),
        meta: {
            layout: AppLayoutsEnum.login
        },
        beforeEnter: [guestMiddleware]
    },
    {
        path: '/:catchAll(.*)',
        name: RouteNamesEnum.notFound,
        component: () => import('@/views/NotFoundView.vue'),
        meta: {
            layout: AppLayoutsEnum.login
        }
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})
router.beforeEach(loadLayoutMiddleware);
export default router